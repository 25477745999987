// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  lite: process.env.REACT_APP_STRIPE_PRICE_LITE,
  plus: process.env.REACT_APP_STRIPE_PRICE_PLUS,
  premium: process.env.REACT_APP_STRIPE_PRICE_PREMIUM,
  pro: process.env.REACT_APP_STRIPE_PRICE_PRO,
  product1: process.env.REACT_APP_STRIPE_PRICE_PRODUCT1,
  product2: process.env.REACT_APP_STRIPE_PRICE_PRODUCT2,
  product3: process.env.REACT_APP_STRIPE_PRICE_PRODUCT3,
  product4: process.env.REACT_APP_STRIPE_PRICE_PRODUCT4,
  product5: process.env.REACT_APP_STRIPE_PRICE_PRODUCT5,
  product6: process.env.REACT_APP_STRIPE_PRICE_PRODUCT6,
};

// Note:
// Paypal doesn't really have id for products/onetime purchase, so we are using stripe price id for paypal as well.
const paypalIds = {
  plus: process.env.REACT_APP_PAYPAL_PLAN_ID_PLUS,
  premium: process.env.REACT_APP_PAYPAL_PLAN_ID_PREMIUM,
  product1: process.env.REACT_APP_STRIPE_PRICE_PRODUCT1,
  product2: process.env.REACT_APP_STRIPE_PRICE_PRODUCT2,
  product3: process.env.REACT_APP_STRIPE_PRICE_PRODUCT3,
  product4: process.env.REACT_APP_STRIPE_PRICE_PRODUCT4,
  product5: process.env.REACT_APP_STRIPE_PRICE_PRODUCT5,
  product6: process.env.REACT_APP_STRIPE_PRICE_PRODUCT6
}

// Get Stripe priceId
export function getStripePriceId(planId) {
  return stripePriceIds[planId];
}

export function getPaypalId(planId){
  return paypalIds[planId]
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object

export function getFriendlyPlanId(id) {
  // Search in the first array
  const firstArrayMatch = Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === id
  );

  if (firstArrayMatch) {
    return firstArrayMatch;
  }

  // Search in the second array
  const secondArrayMatch = Object.keys(paypalIds).find(
    (key) => paypalIds[key] === id
  );

  return secondArrayMatch || null;
}
