"use client";
import ChatSection from "../components/ChatSection";
import { Box, Button, ButtonGroup, Paper, Tab, Tabs, makeStyles, Chip } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import Snackbar from '@material-ui/core/Snackbar';
import { useChat } from "ai/react";
import React, { useState, useEffect }from "react";
import Meta from "../components/Meta";
import { requireAuth } from "../util/auth";
import { useAuth } from "./../util/auth";
import { Link, useRouter } from "./../util/router";
import ChatIcon from '@material-ui/icons/ForumOutlined';
import PostAddIcon from '@material-ui/icons/PostAdd';
import CreateIcon from '@material-ui/icons/Create';
import Chat from "../components/Chat";
import Tiptap from "../components/TipTap";
import Section from "../components/Section";
import PostList from "../components/PostList";
import NoteNav from "../components/NoteNav";
import SortMenu from "../components/SortMenu";
import FilterMenu from "../components/FilterMenu";
import { usePagedPostsByOwner } from "../util/db";
import CalendarView from "../components/CalendarView";
import { POST_TYPE } from "../util/enums";

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    position: 'sticky',
    width: '100%',
    top: 0,
    boxShadow: 'none',
    color: theme.palette.text.primary,
    zIndex: 1000, // Ensure it stays on top of other content
    backgroundColor: theme.palette.background.default, // Optional: Set background color to match your theme
  },
  container: {
    maxWidth: '800px',
    margin: 'auto',
    backgroundColor: theme.palette.background.default
  }
  // tabsContainer: {
  //   [theme.breakpoints.up('md')]: {
  //     maxWidth: '800px', // Adjust based on your preference
  //     margin: 'auto',
  //   }
  // },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box> {/* Padding around the content */}
          {children}
        </Box>
      )}
    </div>
  );
}

function PostListPage(props) {
  document.title = "Posts - Cleve";
  const classes = useStyles();
  const auth = useAuth()
  const router = useRouter();
  //const {data: posts, isLoading} = usePosts()

  const [value, setValue] = useState(1);
  const [open, setOpen] = useState(true);
  const [filters, setFilters] = useState([]);
  const [sortBy, setSortBy] = useState('createdAt');

  const properties = ['tags', 'status', 'type']; 
  const values = {
    type: Object.values(POST_TYPE).map(item => item.id),
    status: ['Draft', 'Edited', 'Scheduled']
  };

  const updateFilters = (newFilters, newSortBy = sortBy) => {
    setFilters(newFilters);
    setSortBy(newSortBy);

    const searchParams = new URLSearchParams();

    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== null && value !== undefined) {
        searchParams.set(key, value);
      }
    });

    if (newSortBy) {
      searchParams.set('sortBy', newSortBy);
    }

    const newSearchString = searchParams.toString();
    router.push({search: newSearchString});
  };

  const handleApplyFilter = (filter) => {
    const newFilters = { ...filters, [filter.property]: filter.value };
    updateFilters(newFilters);
  };

  const handleDeleteFilter = (property) => {
    const { [property]: _, ...newFilters } = filters;
    updateFilters(newFilters);
  };

  const handleSortChange = (newSortBy) => {
    updateFilters(filters, newSortBy);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(router.location.search);
    const urlSortBy = searchParams.get('sortBy') || 'createdAt';
    const initialFilters = {};

    searchParams.forEach((value, key) => {
      if (key !== 'sortBy') initialFilters[key] = value;
    });

    // Prevent setting state if values haven't changed
    if (urlSortBy !== sortBy || JSON.stringify(initialFilters) !== JSON.stringify(filters)) {
      setSortBy(urlSortBy);
      setFilters(initialFilters);
    }
  }, [router.location.search]); // Dependencies ensure this runs only when URL search params change


  useEffect(() => {
    if (auth.user && !auth.user.questions){
      router.push('/onboarding?step=2')
    }
  }, [auth]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <Box> {/* paddingTop should be equal or greater than the height of your tabs to prevent content from being hidden behind the sticky tabs */}
      <Meta title="Cleve" />
      {router.query.paid &&
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal:'center'}}>
        <Alert onClose={handleClose} severity="success">
          { auth.user?.planIsActive && !router.query.credit ? `You are now subscribed to the ${auth.user?.planId} plan 🥳`: ''}
          { router.query.credit ? `You successfully bought ${router.query.credit} credit!` : ''}
        </Alert>
      </Snackbar>
      }
      <Box className={classes.container}>
      <TabPanel value={value} index={0}>
          "Loading"
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box style={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
          {/* <SortMenu onSortChange={handleSortChange} selectedSortBy={sortBy} /> */}
          <FilterMenu properties={properties} values={values} onApplyFilter={handleApplyFilter} />
        </Box>
          {Object.entries(filters).map(([property, value], index) => (
            <Chip
              key={index}
              label={`${property}: ${value}`}
              onDelete={() => handleDeleteFilter(property)}
              color="primary"
              style={{ margin: '5px', textTransform: 'capitalize'}}
            />
          ))}
          <CalendarView {...usePagedPostsByOwner(auth?.user?.id, filters, sortBy)}/>
      </TabPanel>
      </Box>
      <NoteNav/>
    </Box>
  );
}

export default requireAuth(PostListPage);