import { useRef, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { firebaseApp } from "./firebase";
import { string_to_unicode_variant } from "string-to-unicode-variant";

const auth = getAuth(firebaseApp);

export function textToHtmlParagraphs(text) {
  const lines = text.split('\n');
  // Filter out any empty lines and wrap each remaining line in <p> tags
  const paragraphs = lines.filter(line => line.trim() !== '').map(line => `<p>${line}</p>`);
  return paragraphs.join('');
}

export function convertBoldToUnicodeVariant(text) {
  // Regular expression to match text enclosed in **
  const regex = /\*\*(.*?)\*\*/g;

  // Replace function
  const replaceFunc = (match, p1) => {
    // p1 is the captured group (text between **)
    return string_to_unicode_variant(p1, 'bold sans');
  };

  // Replace all matches in the text
  return text.replace(regex, replaceFunc);
}

export async function getAuthHeaders() {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

// Make an API request to `/api/{path}`
export async function apiRequest(path, method = "GET", data) {
  const accessToken = auth.currentUser
    ? await auth.currentUser.getIdToken()
    : undefined;

  try {
    const response = await fetch(`/api/${path}`, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data ? JSON.stringify(data) : undefined,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }

    const jsonResponse = await response.json();

    if (jsonResponse.status === "error") {
      // Automatically signout user if accessToken is no longer valid
      if (jsonResponse.code === "auth/invalid-user-token") {
        signOut(auth);
      }

      throw new CustomError(jsonResponse.code, jsonResponse.message);
    } else {
      return jsonResponse.data;
    }

  } catch (error) {
    if (error instanceof CustomError) {
      throw error;
    }
    // Handle network errors, parsing errors, etc
    throw new CustomError('request_failed', error.message || 'Failed to complete request');
  }
}

// Make an API request to any external URL
export function apiRequestExternal(url, method = "GET", data) {
  return fetch(url, {
    method: method,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : undefined,
  }).then((response) => response.json());
}

// Create an Error with custom message and code
export function CustomError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

// Hook that returns previous value of state
export function usePrevious(state) {
  const ref = useRef();
  useEffect(() => {
    ref.current = state;
  });
  return ref.current;
}

/**
 * Formats numbers with comma for thousands if less than 100k,
 * or with 'k' suffix and 1 decimal point if more than 100k
 * @param {number} num - The number to format
 * @returns {string} - The formatted number
 */
export const formatNumber = (num) => {
  if (!num && num !== 0) return '0';
  
  // For millions
  if (num >= 1000000) {
    const millions = num / 1000000;
    if (millions < 100) {
      const fixed = millions.toFixed(1);
      return fixed.endsWith('.0') ? Math.floor(millions) + 'M' : fixed + 'M';
    }
    return Math.round(millions) + 'M';
  }
  
  // For thousands
  if (num >= 10000) {
    const thousands = num / 1000;
    if (thousands < 100) {
      const fixed = thousands.toFixed(1);
      return fixed.endsWith('.0') ? Math.floor(thousands) + 'k' : fixed + 'k';
    }
    return Math.round(thousands) + 'k';
  }
  
  // For numbers less than 10000
  return num.toLocaleString();
};

/**
 * Generates a formatted caption for LinkedIn Unwrapped sharing
 * @param {Object} linkedinData - The LinkedIn data object
 * @returns {string} - The formatted caption
 */
export const generateWrappedCaption = (linkedinData) => {
  if (!linkedinData) return '';

  const stats = linkedinData.stats || {};
  const monthlySummaries = linkedinData.monthlySummaries || [];
  const lessons = linkedinData.lessons || [];

  return `🎉 Just got my LinkedIn Unwrapped from Cleve.ai!
        
📊 My 2024 stats:
• ${formatNumber(stats.totalPosts || 0)} posts
• ${formatNumber(stats.totalReactions || 0)} total reactions
• ${formatNumber(stats.totalComments || 0)} comments

Key Highlights:
${monthlySummaries.map(summary => `${summary?.emoji} ${summary?.text}`).join('\n- ')}

Top Lessons:
${lessons.map(lesson => `${lesson?.emoji} ${lesson?.text}`).join('\n- ')}

Here's what AI thought about my year:
---
${linkedinData.letter || ''}
---
#LinkedinUnwrapped`;
};
