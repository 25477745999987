import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, useMediaQuery, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useInView } from 'react-intersection-observer';
import analytics from '../util/analytics';
import { redirectToCheckout } from '../util/stripe';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { toTimestamp } from '../util/db';
import { useAuth } from './../util/auth.js';
dayjs.extend(duration);

const useStyles = makeStyles((theme) => ({
  ctaContainer: {
    backgroundColor: '#FFF3D2',
    color: '#000',
    padding: theme.spacing(5, 4),
    textAlign: 'left',
    borderRadius: theme.spacing(1.5),
    margin: theme.spacing(5, 'auto'),
    maxWidth: '600px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(5, 2.5),
    },
  },
  title: {
    fontSize: ({ isMobile }) => isMobile ? '28px' : '30px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    maxWidth: '600px',
  },
  subtitle: {
    fontSize: ({ isMobile }) => isMobile ? '20px' : '28px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  text: {
    fontSize: ({ isMobile }) => isMobile ? '14px' : '18px',
    marginBottom: theme.spacing(3),
    maxWidth: '600px',
    margin: '0 auto',
    whiteSpace: 'pre-line',
  },
  caption: {
    fontSize: ({ isMobile }) => isMobile ? '10px' : '12px',
    maxWidth: '600px',
    margin: '0 auto',
    whiteSpace: 'pre-line',
    color: theme.palette.text.secondary,
  },
  image: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: theme.spacing(1.5),
    marginBottom: theme.spacing(4),
  },
  statsGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  statBox: {
    textAlign: 'center',
    padding: theme.spacing(2),
    backgroundColor: '#19083d',
    borderRadius: theme.spacing(1),
  },
  statValue: {
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#fff',
  },
  statLabel: {
    color: '#fff',
  },
  video: {
    width: '100%',
    maxWidth: '600px',
    borderRadius: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    backgroundColor: '#FFD700',
    color: '#000',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#FFE44D',
    },
  },
  featureList: {
    marginBottom: theme.spacing(3),
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
}));

const TimerCTA = ({ timeLeft, id, linkedinUrl, email, lite=false }) => {
  const auth = useAuth();
  const classes = useStyles();

  let buttonText = ''
  if (lite) {
    buttonText = timeLeft ? 'Get 50% off Cleve Lite ($5/month)' : 'Get 20% off Cleve Lite ($8/month)'
  } else {
    buttonText = timeLeft ? 'Get 30% off Cleve Pro' : 'Get 20% off Cleve Pro'
  }

  return (
    <>
      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={() => {
          analytics.track('Unwrapped CTA Click', {
            linkedinUrl,
            email,
            variation: id,
            timeLeft: timeLeft
          });
          const couponCode = lite ? 'promo_1QhZ3CAZgRS3d3EZ7CCdt57d' : 'promo_1QZrRsAZgRS3d3EZL0mYoTuN';
          // if timeleft expired, use promo code for 20% off
          const couponUrl = timeLeft ? `coupon=${couponCode}` : 'coupon=promo_1QZyh3AZgRS3d3EZcqxlyBhQ';
          const planId = lite ? 'lite' : 'premium';
          const nextUrl = auth.user ? `/purchase/${planId}?${couponUrl}` : `/auth/signup?next=/purchase/${planId}?${couponUrl}`;
          window.open(`https://notes.cleve.ai${nextUrl}`, '_blank');
        }}
        className={classes.button}
      >
        {buttonText}
      </Button>
      {timeLeft && (
        <Box 
          style={{ 
            backgroundColor: '#19083d',
            padding: '8px 16px',
            borderRadius: '8px',
            marginBottom: '4px',
            textAlign: 'center',
            width: '100%'
          }}
        >
          <Typography 
            style={{ 
              color: '#FFD700',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            🔥 Special offer expires in: {timeLeft}
          </Typography>
        </Box>
      )}
      {lite && timeLeft && <Typography 
        style={{ 
          marginTop: '4px'
        }}
        className={classes.caption}
      >
        Billed annually at $60/year
      </Typography>}
    </>
  );
};

// First CTA - Thanks for being here
const ThanksCTA = ({ firstName, isMobile, onCtaClick, title, lastUpdated, timeLeft, id, linkedinUrl, email }) => {
  const classes = useStyles({ isMobile });
  
  return (
    <div className={classes.ctaContainer} style={{marginTop: '20px'}}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>

      <Typography className={classes.text}>
        {`Once again, congrats on an incredible 2024 from all of us at Cleve!
        
        We built this as a way to remind ourselves how far we've come on this journey, and to celebrate each other's 2024. Unwrapped was a last-minute project put together in 2 weeks. Since launch, over 18k+ of you have gotten your Unwrapped's, and we're floored by your support and trust in us 💌
        
        We're a 3-person team based in Malaysia building a helpful, personal AI that helps you gain clarity while building your personal brand.
        
        Hopefully, the LinkedIn Unwrapped is just the start of your journey with us.`}
      </Typography>

      <img 
        src="/cleve-journey.webp"
        alt="Cleve.ai Team"
        className={classes.image}
      />

      <Typography variant="h3" className={classes.subtitle}>
        We believe everyone has a story worth telling
      </Typography>

      <Typography className={classes.text}>
        {[`Unfortunately, most of us are stuck in the daily grind, with little time to share our ideas and market ourselves.
        
        So we built Cleve to help :)
        
        Cleve can:
        • Take voice notes, turn them to content
        • Read images and craft captions
        • Give personalised content ideas
        • Create carousels
        • Deliver monthly reports of your content
        • Edit your posts with AI
        • Create content in 100+ languages
        • Repurpose your content (LinkedIn, X, IG, TikTok, Newsletters)
        
        Join`, <strong> over 8000 entrepreneurs, professionals and creators</strong>, ` who have been using Cleve for their content`]}
      </Typography>

      {/* <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={onCtaClick}
        className={classes.button}
      >
        Try Cleve Free
      </Button> */}
      <TimerCTA timeLeft={timeLeft} id={id} linkedinUrl={linkedinUrl} email={email} />
      <Typography variant="h3" className={classes.subtitle} style={{marginTop: '20px'}}>
        Who are we?
      </Typography>
      <Typography className={classes.text}>
        {[`Hello! We're 3 Gen Z's from Malaysia that set off on this startup journey in December last year. It's been insanely fun.

We raised funds from Antler, a global VC - to build the workspace for building your Personal Brand.

Our team is made of: 

Ashvin Praveen, CEO - `, <a href="https://www.linkedin.com/in/ashvinpraveen/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,`
Lizzie Tan, CMO - `, <a href="https://www.linkedin.com/in/lizzietan/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,`
Muhammad Fathy Rashad, CTO - `, <a href="https://www.linkedin.com/in/mfathyrashad/" target="_blank" rel="noopener noreferrer">LinkedIn</a>,", ", <a href="https://www.tiktok.com/@rashadventure" target="_blank" rel="noopener noreferrer">TikTok</a>,
`

Feel free to connect with us on LinkedIn too! We regularly share our build in public journey there too, including new updates, thoughts and more.

We're constantly active at the chat at the bottom right btw! if you'd like you can always say hi, ask any questions you have or share your feedback/ideas there 👋`]}
      </Typography>
      {/* <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={onCtaClick}
        className={classes.button}
      >
        Try Cleve Free
      </Button> */}
      <TimerCTA timeLeft={timeLeft} id={id} linkedinUrl={linkedinUrl} email={email} />
      <Typography variant="h3" className={classes.subtitle} style={{marginTop: '20px'}}>
        Lastly just want to say thanks again for being part of our startup story 💛
      </Typography>
      <Typography className={classes.text}>
        {`This #LinkedInUnwrapped project has been absolutely amazing - it’s been incredible to see all your posts and heartwarming messages and we’ve got hundreds of the nicest DM’s from strangers from across the world!

        Wishing you a very Merry Christmas and Happy New year :)
        
        Yours,
        Ashvin, Lizzie and Rashad
        `}
      </Typography>
    </div>
  );
};

// Second CTA - Unwrapped Stats
const StatsCTA = ({ firstName, isMobile, onCtaClick, lastUpdated }) => {
  const classes = useStyles({ isMobile });
  
  return (
    <>      
      <Typography variant="h2" className={classes.title}>
        You're amongst the top creators on LinkedIn
      </Typography>

      <Typography className={classes.text}>
        Want to keep growing your personal brand in 2025?
      </Typography>

      <Box className={classes.statsGrid}>
        <Box className={classes.statBox}>
          <Typography className={classes.statValue}>18k+</Typography>
          <Typography className={classes.statLabel}>Unwrapped Generated</Typography>
        </Box>
        <Box className={classes.statBox}>
          <Typography className={classes.statValue}>100+</Typography>
          <Typography className={classes.statLabel}>Countries</Typography>
        </Box>
      </Box>

      <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={onCtaClick}
        className={classes.button}
      >
        Join other creators on Cleve
      </Button>
    </>
  );
};

const ImageCTA = ({ firstName, isMobile, onCtaClick, title, description, buttonText, timeLeft, id, linkedinUrl, email }) => {
  const classes = useStyles({ isMobile });  
  return (
    <div className={classes.ctaContainer} style={{paddingTop: '20px', paddingBottom: '10px'}}>

      
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>

      {/* <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={() => redirectToCheckout('premium', 'promo_1QZrdbAZgRS3d3EZzdH1jVsO')}
        className={classes.button}
      >
        {buttonText}
      </Button> */}
      <TimerCTA timeLeft={timeLeft} id={id} linkedinUrl={linkedinUrl} email={email} />
      <Typography className={classes.text} style={{marginTop: '20px'}}>
        {description}
        {
        `What you will get:

        - Get monthly reports of your content performance!
        - Personalized content ideas
        - Turn voice notes to content
        - Carousel generation
        - Edit your posts with AI
        - Create content in 100+ languages
        - Repurpose your content (LinkedIn, X, IG, TikTok, Newsletters)
        - Schedule your posts (Coming soon)
        - And more! (new features monthly)
        `}
      </Typography>
      <img 
        src="/wrapped/report-cta.webp"
        alt="Report CTA"
        className={classes.image}
        style={{marginBottom: '10px', maxWidth: '600px'}}
      />
      <Typography className={classes.text} style={{marginTop: '20px'}}>
        {description}
        {
        `What you will get:

        - Get monthly reports of your content performance!
        - Personalized content ideas
        - Turn voice notes to content
        - Carousel generation
        - Edit your posts with AI
        - Create content in 100+ languages
        - Repurpose your content (LinkedIn, X, IG, TikTok, Newsletters)
        - Schedule your posts (Coming soon)
        - And more! (new features monthly)
        `}
      </Typography>
    </div>
  );
};

const LiteCTA = ({ firstName, isMobile, onCtaClick, title, description, buttonText, timeLeft, id, linkedinUrl, email }) => {
  const classes = useStyles({ isMobile });  
  return (
    <div className={classes.ctaContainer} style={{paddingTop: '20px', paddingBottom: '10px'}}>

      
      <Typography variant="h2" className={classes.title}>
        Want to get monthly reports of your content like LinkedIn Unwrapped? ({timeLeft ? '50%' : '20%'} OFF 🔥)
      </Typography>

    
      
      <Typography className={classes.text} style={{marginTop: '10px', marginBottom: '5px'}}>
        {`For less than a coffee a month, get all the data and insights you need to rapidly grow on LinkedIn in 2025. Straight to your inbox 💌`}
      </Typography>
      <TimerCTA lite={true} timeLeft={timeLeft} id={id} linkedinUrl={linkedinUrl} email={email}/>
      <img 
        src="/wrapped/report-cta.webp"
        alt="Report CTA"
        className={classes.image}
        style={{marginTop: '10px', marginBottom: '0px', maxWidth: '600px'}}
      />
      <Typography className={classes.caption} style={{marginTop: '10px', }}>
        {`Trusted by 5500+ founders, professionals and thought leaders globally`}
      </Typography>
      <img 
      src="/backed_by_antler.webp"
      alt="Backed by antler"
      className={classes.image}
      style={{marginBottom: '0px', marginTop: '10px', width: '340px',maxWidth: '600px'}}
    />
    </div>
  );
};

// Third CTA - Video Demo
const VideoCTA = ({ firstName, isMobile, onCtaClick, title, description, buttonText, timeLeft, id, linkedinUrl, email }) => {
  const classes = useStyles({ isMobile });
  
  return (
    <div className={classes.ctaContainer}>
      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>

      <Typography className={classes.text}>
        {description}
      </Typography>

      <iframe 
        width="560" 
        height={isMobile ? "200" : "315"}
        src="https://www.youtube.com/embed/3-e88JJqO78?si=v-qUF72Em_CkKi92" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        className={classes.video}
      />

      {/* <Button
        fullWidth
        variant="contained"
        size="large"
        onClick={onCtaClick}
        className={classes.button}
      >
        {buttonText}
      </Button> */}
      <TimerCTA timeLeft={timeLeft} id={id} linkedinUrl={linkedinUrl} email={email} />
    </div>
  );
};

const CTA1 = ({...props}) => {
  return <VideoCTA 
    title={`Looking to start your 2025 LinkedIn game strong ${props.firstName}?🔥 `}
    description={[`Growing on LinkedIn is all about posting consistent, quality content.
But most struggle with:
• Not knowing what to post
• No time to write
• Writing content that performs

Join 8,000+ professionals and creators who use Cleve to build their brand, get bigger opportunities, and drive sales.

`, <strong>Watch this 2 min video</strong>, ` to understand how you can use Cleve to turn your quick thoughts into content`]}
    buttonText="Try Cleve for free"
    {...props} 
  />
}

const CTA2 = ({...props}) => {
  return <VideoCTA 
    title={`Feeling motivated after seeing your 2024 results ${props.firstName}?🔥 `}
    description={[`Double down on LinkedIn in 2025, without spending double the time - by leveraging Cleve's AI.

Join 8,000+ professionals and creators who use Cleve to build their brand, get bigger opportunities, and drive sales.

`, <strong>Watch this 2 min video</strong>, ` to understand how you can use Cleve to turn your quick thoughts into content`]}
    buttonText="Try Cleve for free"
    {...props} 
  />
}

const CTA3 = ({...props}) => {
  return <>
    <VideoCTA 
      title={`Make 2025 your best year yet ✨`}
      description={[`Cleve is your AI copilot for building your personal brand.

    Join 8,000+ professionals and creators who use Cleve to build their brand, get bigger opportunities, and drive sales.

    `, <strong>Watch this 2 min video</strong>, ` to understand how you can use Cleve to turn your quick thoughts into content`]}
      buttonText="Try Cleve for free"
      {...props} 
    />
    <ThanksCTA {...props} title="Thanks for being a part of this 💛"/>
  </>
}

const CTA4 = ({...props}) => {
  return <ThanksCTA {...props} title={`thanks for being here ${props.firstName}💛`}/>
}

const CTA5 = ({...props}) => {
  return <ImageCTA {...props} title={`Want to get monthly reports of your content like Linkedin Unwrapped?`}/>
}

const CTA6 = ({...props}) => {
  return <ImageCTA {...props} title={`Need help with your LinkedIn content?`}/>
}

const CTA7 = ({...props}) => {
  return <ImageCTA {...props} title={`Need help posting more consistently?`}/>
}

export const CTA_COMPONENTS = [
  // {
  //   id: 'cta1',
  //   Component: CTA1,
  // },
  // {
  //   id: 'cta2',
  //   Component: CTA2,
  // },
  // {
  //   id: 'cta3',
  //   Component: CTA3,
  // },
  {
    id: 'cta4',
    Component: CTA4,
  },
  // {
  //   id: 'cta5',
  //   Component: CTA5,
  // },
  // {
  //   id: 'cta6',
  //   Component: CTA6,
  // },
  // {
  //   id: 'cta7',
  //   Component: CTA7,
  // },
  {
    id: 'lite',
    Component: LiteCTA,
  }
];

const UnwrappedCTA = ({ linkedinUrl, email, firstName, className, linkedinData, timeLeft, selectedVariation }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles({ isMobile });

  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  React.useEffect(() => {
    if (inView) {
      console.log('Scrolled to CTA', inView);
      analytics.track('Unwrapped CTA View', {
        linkedinUrl,
        email,
        variation: selectedVariation.id
      });
    }
  }, [inView]);

  const handleCTAClick = () => {
    analytics.track('Unwrapped CTA Click', {
      linkedinUrl,
      email,
      variation: selectedVariation.id
    });
    window.open('https://notes.cleve.ai', '_blank');
  };

  const SelectedCTA = selectedVariation.Component;

  return (
    <Box 
      ref={ref}
      className={`${className}`}
    >
      <SelectedCTA 
        firstName={firstName}
        isMobile={isMobile}
        onCtaClick={handleCTAClick}
        lastUpdated={linkedinData?.lastUpdated}
        id={selectedVariation.id}
        linkedinUrl={linkedinUrl}
        email={email}
        timeLeft={timeLeft}
      />
    </Box>
  );
};

export default UnwrappedCTA; 