import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Dialog, Icon, Divider, SvgIcon, Drawer, Avatar, LinearProgress, Box, BottomNavigation, BottomNavigationAction, List, ListItem, ListItemText, ListItemIcon, DialogContent, Button, CircularProgress, Typography } from '@material-ui/core';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Home, Edit, PostAdd, Settings, Explore } from '@material-ui/icons';
import { Link, useLocation } from 'react-router-dom';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { CalendarToday } from '@material-ui/icons';
import { useAuth } from '../util/auth';
import { Home24Regular, Lightbulb24Regular, Note24Regular, Compose24Regular, Calendar24Regular, Glance24Regular, Textbox24Regular, Settings24Regular, PersonFeedback24Regular, SignOut24Regular, Gift24Regular } from "@fluentui/react-icons";
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.MuiListItemIcon-root':{
      minWidth: '36px'
    }
  },
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
  },
  drawer: {
    flexShrink: 0,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: '15vw',
    maxWidth: 240,
    paddingTop: '50px',
    

      [theme.breakpoints.down('lg')]: {
        '& span': {
          fontSize: '0.8rem',
        },
        '& .MuiListItem-root': {
          paddingTop: '4px',
          paddingBottom: '4px'
        }
      },
    
  },
  drawerContainer: {
    overflow: 'auto',
  }
}));

const NoteNav = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const auth = useAuth()
  const [menuState, setMenuState] = useState(null);
  const navLinks = [
    // { path: '/notes/new', title: 'Quick Note', icon: <SvgIcon><Compose24Regular/></SvgIcon> },
    { path: '/', title: 'Notes', icon:  <SvgIcon><Note24Regular /></SvgIcon> },
    { path: '/posts', title: 'Posts', icon: <SvgIcon><Textbox24Regular /></SvgIcon> },
    { path: '/explore', title: 'Explore', icon: <SvgIcon><Glance24Regular /></SvgIcon> },
    { path: '/calendar', title: 'Calendar', icon: <SvgIcon><Calendar24Regular /></SvgIcon>, desktopOnly: true},
    // { path: 'https://cleve.ai/affiliates', title: 'Refer and Earn', icon: <SvgIcon><Gift24Regular/></SvgIcon>, desktopOnly: true}
  ];

  const [daysRemaining, setDaysRemaining] = useState(0);

  const handleOpenMenu = (event, id) => {
    // Store clicked element (to anchor the menu to)
    // and the menu id so we can tell which menu is open.
    setMenuState({ anchor: event.currentTarget, id });
  };

  
  const handleCloseMenu = () => {
    setMenuState(null);
  };

  useEffect(() => {
    const createdAt = new Date(auth?.user?.createdAt.seconds * 1000);
    const endDate = dayjs(createdAt).add(7, 'day');
    const today = dayjs();

    const daysLeft = endDate.diff(today, 'day');

    // Return the number of days left (0 if negative)
    setDaysRemaining(Math.max(0, daysLeft));
  }, [auth?.user?.createdAt]);

  const renderMobileNav = () => (
    <BottomNavigation className={classes.root} showLabels>
      {navLinks.map((link) => (
        // split path by '/' and get the first part
        // to match the current location
        link.desktopOnly ? null :
        <BottomNavigationAction
          key={link.path}
          component={Link}
          to={link.path}
          label={link.title}
          icon={React.cloneElement(link.icon, {
            color: location.pathname.split('/')[1] === link.path.split('/')[1] ? 'primary' : 'inherit'
          })}
        />
      ))}
    </BottomNavigation>
  );

  const renderDesktopNav = () => (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <List>
          <Button
                variant="contained"
                color='primary'
                to={'/notes/new'}
                
                component={Link}
                style={{justifyContent: 'flex-start',borderRadius: '20px', padding: '10px 20px', margin: '5px', width: '90%', border: 'solid 1px rgba(0, 0, 0, 0.1)'}}
                startIcon={<SvgIcon><Compose24Regular/></SvgIcon>}
              >
                Quick Note
              </Button>
          {navLinks.map((link) => (
            <ListItem 
              key={link.path} 
              button 
              component={link.path.startsWith('http') ? 'a' : Link} 
              to={!link.path.startsWith('http') ? link.path : undefined}
              href={link.path.startsWith('http') ? link.path : undefined}
              target={link.path.startsWith('http') ? '_blank' : undefined}
              rel={link.path.startsWith('http') ? 'noopener noreferrer' : undefined}
              style={{backgroundColor: 'primary'}}
            >
              <ListItemIcon>
                {React.cloneElement(link.icon, {
                  color: location.pathname === link.path ? 'primary' : 'inherit',
                })}
              </ListItemIcon>
              <ListItemText primary={link.title} />
            </ListItem>
          ))}
        </List>
        
        {!auth.user?.planIsActive &&
        <ListItem style={{position: 'absolute', bottom: '175px'}}>
          <Box style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <LinearProgress variant='determinate' value={Math.max((daysRemaining/7)*100, 5)} style={{width: '100%', height: '6px', marginBottom: '10px'}}/>
            <Typography gutterBottom variant='caption'>
              <strong> {daysRemaining} days remaining in your Cleve Pro free trial. </strong> 
              Upgrade to keep unlimited AI features, voice mode and more.
            </Typography>
            <Button variant='contained' color='primary' component={Link} to={'/pricing'} style={{width: '100%', height: '100%'}}>
              Explore Benefits
            </Button>
          </Box>
        </ListItem>
        } 
        
        <ListItem button 
          onClick={(event) => {
                      handleOpenMenu(event, "account-menu");
                    }} 
          style={{position: 'absolute', bottom: '105px'}}>
          <ListItemIcon>
            <Avatar alt={auth?.user?.name ?? auth?.user?.displayName} src={auth.user?.photoURL} style={{width: '30px', height: '30px', marginRight: '5px'}}/>
                    
          </ListItemIcon>
          <ListItemText 
            primary={'Profile'}
            secondary={`${auth?.user?.name ?? auth?.user?.displayName ?? 'Account'}`}
            secondaryTypographyProps={{style: {fontSize: '12px'}}}
          />
        </ListItem>                    
        <ListItem button component={Link} to={'/settings/general'} style={{position: 'absolute', bottom: '60px'}}>
          <ListItemIcon>
            <SvgIcon color={location.pathname === '/settings/general' ? 'primary' : 'inherit'}>
              <Settings24Regular/>
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button 
        component={'a'} 
          to='https://cleve.ai/affiliates'
          href='https://cleve.ai/affiliates'
          target='_blank'
          rel='noopener noreferrer'
         style={{position: 'absolute', bottom: '15px'}}>
          <ListItemIcon>
            <SvgIcon>
              <Gift24Regular/>
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary="Refer and Earn" />
        </ListItem>

        </div>
      <Menu
                    id="account-menu"
                    open={
                      menuState && menuState.id === "account-menu"
                        ? true
                        : false
                    }
                    anchorEl={menuState && menuState.anchor}
                    getContentAnchorEl={undefined}
                    onClick={handleCloseMenu}
                    onClose={handleCloseMenu}
                    keepMounted={true}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                  >
                    <MenuItem
                    style={{
                      width: '170px',
                    }}
                      onClick={(event) => {
                        auth.signout();
                      }}
                    >
                      <SvgIcon>
                        <SignOut24Regular/>
                      </SvgIcon>
                      <Typography style={{marginLeft: '10px'}}>Sign Out</Typography>
                    </MenuItem>
                  </Menu>
    </Drawer>
  );

  return isMobile ? renderMobileNav() : renderDesktopNav();
};

export default NoteNav;
