import React, { useEffect, useState } from 'react';
import {
  Stepper,
  MobileStepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Chip,
  Typography,
  Grid,
  Container,
  makeStyles,
  Box,
  CircularProgress,
  LinearProgress,
  Fade,
  useMediaQuery,
  useTheme,
  Link,
  Avatar
} from '@material-ui/core';
import LoadingBackdrop from './LoadingBackdrop';
import FaceIcon from '@material-ui/icons/Face'; // Example icon
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { updateUser } from '../util/db';
import { useAuth } from '../util/auth';
import { useRouter } from '../util/router';
import { apiRequest } from '../util/util';
import { useCompletion } from "ai/react";
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/react";
import { requireAuth } from '../util/auth';
import { GOALS, TOPICS } from '../util/enums';
import analytics from '../util/analytics';
import CleveLoading from './CleveLoading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textTransform: 'inherit',
    maxWidth: '500px',
    fontWeght: 'bold'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  stepLabel: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(2),
    width: '380px',
    maxWidth: '85vw'
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: '500px',
    maxWidth: '700px'
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    textAlign: 'start'
  },
  container: {
    padding: theme.spacing(1),

  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  tutorialImage: {
    width: '100%',
    maxWidth: '450px',
    minHeight: '277px',
  },
  linkedinButton: {
    marginTop: '20px',
    textTransform: 'none',
    backgroundColor: '#0077b5',
    color: 'white',
    '&:hover': {
      backgroundColor: '#006292',
    }
  }
}));

const getSteps = () => [
  'Welcome to Cleve',
  'Connect your LinkedIn Account',
  'Enter your LinkedIn Profile URL',
  'What kind of topics do you like?',
  'Who are you creating for?',
  'Describe yourself',
  'Thank you'
];

const getButtonText = (step) => {
  switch (step) {
    case 0:
      return 'Get Started';
    case 1:
      return "I'll do this later";
    case 5:
      return 'Submit'
    case 6:
      return 'Start Cleve';
    default:
      return step > 6 ? 'Continue' : 'Next';
  }

}

function isWorkEmail(email) {
  // List of common public email domains
  const publicDomains = [
    "gmail.com", "yahoo.com", "hotmail.com", "outlook.com", "aol.com",
    "live.com", "msn.com", "icloud.com"
  ];
  const domain = email.split('@')[1];

  return !publicDomains.includes(domain);
}


const OnboardingForm = () => {
  const classes = useStyles();
  const auth = useAuth();
  const router = useRouter();
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    complete, completion, input, stop, isLoading
  } = useCompletion({
    api: '/api/completion',
    body: { user: auth?.user },
  });

  const [activeStep, setActiveStep] = useState(router.query.step ? parseInt(router.query.step) : 0);
  const [isSettingUp, setIsSettingUp] = useState(false);
  const [formData, setFormData] = useState({
    path: 'Generate content faster',
    goals: [],
    topics: [],
    audience: '',
    description: '',
    name: '',
    linkedinUrl: '', // New field for LinkedIn Profile URL
  });
  const [isValidLinkedInUrl, setIsValidLinkedInUrl] = useState(true);

  const validateLinkedInUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[A-Za-z0-9-]+(\/?|\?.*)$/;
    return regex.test(url);
  };

  useEffect(() => {
    // prefill form data if user is already onboarded
    if (auth.user) {
      const user = auth.user
      setFormData(prev => ({
        ...prev,
        path: user.path || prev.path,
        goals: user.goals || prev.goals,
        topics: user.topics || prev.topics,
        audience: user.audience || prev.audience,
        description: user.description || prev.description,
        name: (user.name || user.displayName) ? (user.name || user.displayName) : prev.name,
        linkedinUrl: user.linkedinUrl || prev.linkedinUrl, // New field for LinkedIn Profile URL
      }))
      console.log(user)

      const emailLookup = async () => {
        console.log('looking up your profile from email...')
        try {
          const emailLookup = await apiRequest("linkedin-lookup", "POST", { email: user.email, lookup_depth: 'deep' });
          if (emailLookup.linkedin_profile_url) {
            setFormData(prev => ({
              ...prev,
              linkedinUrl: emailLookup.linkedin_profile_url,
              // linkedinProfile: emailLookup.profile,
              // twitterUrl: emailLookup.twitter_profile_url,
              // facebookUrl: emailLookup.facebook_profile_url,
              // lastUpdatedProfile: emailLookup.last_updated
            }))
            console.log(formData)
          }
        } catch (error) {
          console.log(error)
          toast.error('Failed to get LinkedIn Profil from email')
          Sentry.captureException(new Error('Failed to get LinkedIn Profile from email'), { extra: { error } });
          //throw new Error('Failed to get LinkedIn Profile');
        }
      }

      if (isWorkEmail(user.email) && !user.linkedinUrl) {
        emailLookup()
      }

    }
  }, [auth.user])

  const steps = getSteps();

  const isStepComplete = () => {
    switch (activeStep) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        return true//formData.linkedinUrl.trim() !== '';
      case 3:
        return formData.topics.length > 0;
      case 4:
        return formData.audience.trim() !== '';
      case 5:
        return formData.description.trim() !== '';
      default:
        return true; // For any case that isn't covered, default to false
    }
  };

  const fetchLinkedinProfile = async () => {
    console.log(formData.linkedinUrl)
    let linkedinProfile = null
    try {
      linkedinProfile = await apiRequest("linkedin", "POST", { linkedin_profile_url: formData.linkedinUrl });
    }
    catch (error) {
      toast.error('Failed to get LinkedIn Profile')
      Sentry.captureException(new Error('Failed to get LinkedIn Profile'), { extra: { error } });
      //throw new Error('Failed to get LinkedIn Profile');
    }

    try {

      await updateUser(auth.user.uid, {
        linkedinProfile,
        linkedinUrl: formData.linkedinUrl
      });
      analytics.track('updatedLinkedinProfile');
    }
    catch (error) {
      console.log(error)
      toast.error('Failed to update user information')
      Sentry.captureException(new Error('Failed to update user information'), { extra: { error } });
    }

    //autofill form with linkedin data
    if (!formData.audience || !formData.description) {
      const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
      let completion = null
      let questions = null
      try {
        completion = await complete('', {
          headers: { Authorization: `Bearer ${token}` },
          body: { task: 'autofill', linkedinProfile }
        });

        console.log(completion)
        const autofilledData = JSON.parse(completion)
        if (autofilledData.audience && autofilledData.description && !formData.audience && !formData.description) {
          setFormData(prev => ({
            ...prev,
            name: autofilledData.name,
            audience: autofilledData.audience,
            description: autofilledData.description
          }))
        }
      } catch (error) {
        console.log(error)
        toast.error('Failed to generate autofilled data')
        Sentry.captureException(new Error('Failed to generate autofilled data'), { extra: { completion, questions, error } });
        //throw new Error('Failed to generate personalized questions');
      }
    }
    await generateQuestions()
    setIsSettingUp(false)
  }

  const generateQuestions = async () => {
    // generate initial questions
    const token = auth.user ? await auth.user.auth.currentUser.getIdToken() : undefined;
    let completion = null
    let questions = null
    try {
      completion = await complete('', {
        headers: { Authorization: `Bearer ${token}` },
        body: { task: 'bulk-generate-questions', formData, user: auth.user, questionAmount: 10 }
      });

      // console.log(completion)
      const JSONresponse = JSON.parse(completion)
      // console.log(JSONresponse)
      questions = JSONresponse.questions
      if (!questions) {
        questions = JSONresponse
      }
    } catch (error) {
      toast.error('Failed to generate personalized questions')
      Sentry.captureException(new Error('Failed to generate personalized questions'), { extra: { completion, questions, error } });
      //throw new Error('Failed to generate personalized questions');
    }

    // console.log(questions)
    //check if questions is an array and at least have 20 elements
    if (questions && questions.length < 5) {
      Sentry.captureException(new Error('Questions is undefined or less than 30'), { extra: { questions } });
      //throw new Error('Failed to generate personalized questions');
    }

    //Save questions
    try {
      await updateUser(auth.user.uid, {
        questions,
      });
      console.log('User initial questions updated')
    }
    catch (error) {
      console.log(error)
      toast.error('Failed to update user inititial questions')
      Sentry.captureException(new Error('Failed to update user initial questions'), { extra: { error } });
    }
  }


  const handleNext = async () => {
    if (!isStepComplete()) {
      alert("Please complete the current step before proceeding.");
      return;
    }
    if (activeStep === steps.length - 1) {
      analytics.track('finishOnboarding');
      router.push('/');
    } else {
      router.push({ pathname: router.pathname, query: {} })
    }

    if (auth.user?.uid && activeStep === 2) {
      setIsSettingUp(true)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);

      await fetchLinkedinProfile()
    }
    else if (auth.user?.uid && activeStep === 5) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      try {
        await updateUser(auth.user.uid, {
          ...formData,
        });
        console.log('User data updated')
        console.log(formData)
      }
      catch (error) {
        console.log(error)
        toast.error('Failed to update user data')
        Sentry.captureException(new Error('Failed to update user data'), { extra: { error } });
      }

      //Generate questions
      try {
        apiRequest('jobs', 'POST', { name: 'bulk-generate-questions', data: { task: 'bulk-generate-questions', formData, user: auth.user, questionAmount: 50 } })
        apiRequest('jobs', 'POST', { name: 'bulk-generate-titles', data: { task: 'bulk-generate-titles', formData, user: auth.user, questionAmount: 50 } })
      } catch (e) {
        console.log(e)
        toast.error('Failed to generate question in the background')
        Sentry.captureException(new Error('Failed to update user data'), { extra: { e } });
      }
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }


  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setFormData({
      path: '',
      goals: [],
      topics: [],
      audience: '',
      description: '',
      name: '',
      linkedinUrl: window.localStorage.getItem('linkedinUrl') || '',
    });
  };

  const handleSelect = (field, value, multiple = false) => {
    if (multiple) {
      setFormData((prevData) => ({
        ...prevData,
        [field]: prevData[field].includes(value) ? prevData[field].filter((v) => v !== value) : [...prevData[field], value]
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));

    if (name == 'linkedinUrl') {
      setIsValidLinkedInUrl(validateLinkedInUrl(value));
    }
  };

  const GetStepContent = (stepIndex) => {
    // Cases for each step are the same as before, just add a new case for LinkedIn URL
    switch (stepIndex) {
      case 0:
        return (

          <Container>
            <img src="/cleve-text-logo.webp" alt="Cleve Logo" style={{ height: '60px', padding: '15px' }} />
            <h1>Say hello to your new content workspace</h1>
            <Fade in={true} timeout={1000}>
              <img src="/guide1a.webp" alt="Cleve Diagram" style={{ width: '100%', maxWidth: '320px' }} />
            </Fade>
            {/* <Typography variant="body2">Cleve helps you capture your fleeting thoughts & content ideas, and turn them into posts for various social media channels</Typography> */}
          </Container>

        )
      case 1:
        return (
          <Container>
            <h1>Personalize your experience</h1>
            <Typography variant="body">Users who add LinkedIn gain a 70% more personalised experience</Typography>
            <Button variant="contained" size="large" onClick={() => window.location.href = '/api/linkedin-auth'} className={classes.linkedinButton}>Connect LinkedIn</Button>
            <Typography variant="body2" style={{fontSize: '0.8rem', opacity: 0.8, marginTop: '15px'}}>Cleve securely connects to your LinkedIn to help create better, personalised content and give you more relevant content ideas.</Typography>
          </Container>
        )
      case 2:
        if (auth.user?.linkedinAccessToken && auth.user?.linkedinUrl) {
          return (
            <div>
              <h1>Is this your Linkedin profile?</h1>
              <Typography variant='body2'>We've connected with your linkedin profile. <br />Please double check the URL below.</Typography>
              <Box style={{ marginTop: '24px' }}>
                <TextField
                  error={!isValidLinkedInUrl}
                  name="linkedinUrl"
                  label="LinkedIn Profile URL"
                  placeholder="https://www.linkedin.com/in/your-profile/"
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  onChange={handleInputChange}
                  value={formData.linkedinUrl}
                  helperText={!isValidLinkedInUrl ? "Please enter a valid LinkedIn Profile URL" : ""}
                />
              </Box>
            </div>
          );
        }
        else {
          return (
            <div>
              <h1>What's your LinkedIn profile?</h1>
              <Typography variant='body2'>This helps Cleve ask more relevant questions based on your experiences.</Typography>

              <Box style={{ marginTop: '24px' }}>
                <TextField
                  error={!isValidLinkedInUrl}
                  name="linkedinUrl"
                  label="LinkedIn Profile URL"
                  placeholder="https://www.linkedin.com/in/your-profile/"
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  onChange={handleInputChange}
                  value={formData.linkedinUrl}
                  helperText={!isValidLinkedInUrl ? "Please enter a valid LinkedIn Profile URL" : ""}
                />
              </Box>
              {isMobile ?
                <div style={{ borderRadius: '5px', maxHeight: '300px', width: '150px', overflow: 'hidden', margin: 'auto' }}>
                  <video style={{ height: '100%', width: '100%' }} autoPlay loop muted playsInline>
                    <source src='/linkedin-mobile-tutorial.webm' type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                :
                <div style={{ borderRadius: '5px', maxHeight: '400px', width: '500px', overflow: 'hidden', margin: 'auto' }}>
                  <video style={{ height: '100%', width: '100%' }} autoPlay loop muted playsInline>
                    <source src='/linkedin-desktop-tutorial.webm' type="video/webm" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              }
            </div>
          );
        }
      case 3:
        return (
          <div>
            <h1>What kind of topics do you like to talk about?</h1>
            <div className={classes.chipContainer}>
              {TOPICS.map((topic) => (
                <div key={topic.topic}>
                  <Box mt={2} mb={1.5}><strong>{topic.topic}</strong></Box>
                  {topic.subtopics.map((subtopic) => (
                    <Chip
                      key={subtopic}
                      //icon={<FaceIcon />}
                      label={subtopic}
                      clickable
                      color={formData.topics.includes(subtopic) ? "primary" : "default"}
                      onClick={() => handleSelect('topics', subtopic, true)}
                      className={classes.chip}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <h1>Who are you creating content for?</h1>
            <Typography variant="body2">This helps Cleve craft your content with your audience in mind</Typography>
            <Box style={{ marginTop: '24px' }}>
              <TextField
                name="audience"
                label="Audience"
                placeholder='Startup founders, corporate decision makers in sustainability spaces, consultants'
                fullWidth
                variant="outlined"
                className={classes.textField}
                onChange={handleInputChange}
                value={formData.audience}
                multiline
                minRows={4}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </div>

        );
      case 5:
        return (
          <div>
            <h1>Describe yourself</h1>
            <Typography variant="body2">It can be pretty brief. How would you introduce yourself when meeting someone new?</Typography>
            <Box style={{ marginTop: '24px' }}>
              <TextField
                name="description"
                label="Describe yourself"
                placeholder="I am a marketer passionate in X"
                fullWidth
                variant="outlined"
                className={classes.textField}
                onChange={handleInputChange}
                value={formData.description}
                multiline
                minRows={4}
                maxRows={8}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </div>

        );
      case 6:
        return (
          <div>
            <div style={{ borderRadius: '20px', maxHeight: '300px', width: '160px', overflow: 'hidden', margin: 'auto' }}>
              <video style={{ height: '100%', width: '100%' }} autoPlay loop muted playsInline>
                <source src='/cat-gif.webm' type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <Box style={{ marginTop: '20px' }}>
              <Typography>
                <strong>{isSettingUp ? "Adding finishing touches..." : "Congrats! 🥳🎊 "}</strong>
                <br />
                <br />
              </Typography>
              <Typography variant='subtitle2'>
                {isSettingUp ? "Here’s a cute cat to entertain you meanwhile (you’re welcome)" : "Your Cleve profile is fully setup and ready to go"}
              </Typography>
            </Box>
          </div>
        )
      default:
        return 'Unknown step';
    }
  };

  return (
    <Container className={classes.container}>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you're finished
            </Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <div style={{ maxWidth: '700px', margin: 'auto', marginBottom: '20px' }}>
              {activeStep > 4 && <Typography variant='subtitle2'> {isSettingUp ? 'Personalising Cleve for you...' : 'Ready'}  </Typography>}
              <LinearProgress style={{ margin: '10px' }} value={Math.min(activeStep * 100 / 5, 100)} variant={isSettingUp ? 'indeterminate' : 'determinate'} />
            </div>
            <Container className={classes.formContainer}>
              {GetStepContent(activeStep)}
            </Container>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 'auto', maxWidth: '500px', paddingTop: '20px' }}>
              <Button variant={activeStep === 1 ? "default" : "contained"} color={activeStep === 1 ? "default" : "primary"} onClick={handleNext} disabled={!isStepComplete() || (isSettingUp && activeStep === steps.length - 1)} className={classes.button}>
                <Typography> {getButtonText(activeStep)} </Typography>
              </Button>
              {activeStep === 1 || activeStep === 2 &&
                <Button onClick={handleNext} size='small' className={classes.backButton}>
                  <Typography variant='subtitle2'>I'll do this later</Typography>
                </Button>
              }
              {activeStep >= 3 && activeStep !== 5 &&
                <Button disabled={activeStep === 0 || activeStep === 6} onClick={handleBack} size='small' className={classes.backButton}>
                  <Typography variant='subtitle2'>Back</Typography>
                </Button>
              }
              
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default requireAuth(OnboardingForm);